import serializeLink from './serialize-link'

const serializeCta = (cta) => {
  const link = cta?.link?.[0]
  return link && link?.label
    ? {
        children: cta?.link?.[0]?.label ?? null,
        ...(serializeLink(cta.link?.[0]?.url) ?? null),
      }
    : null
}

export const serialiazeCtaList = (list) => {
  return list?.map((cta) => serializeCta(cta)) ?? []
}

export default serializeCta
