import classnames from 'classnames/bind'
import React from 'react'

import InlineCta from '~/components/InlineCta'
import { LinkProps } from '~/components/Link'
import RichText, { RichTextProps } from '~/components/RichText'
import Separator from '~/components/Separator'
import SplittedSection from '~/components/SplittedSection'

import { useStyle } from '~/providers/StyleProvider'

import { ReactComponent as Logotype } from './logotype.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FooterProps {
  className?: string
  links?: LinkProps[]
  credit?: RichTextProps
  moreInfos?: RichTextProps[]
  socialNetworks?: RichTextProps
  color: string
  backgroundColor: string
}
/**
 * Footer component
 * @param props
 * @returns Component
 */
function Footer({
  className,
  links,
  credit,
  socialNetworks,
  moreInfos,
  color,
  backgroundColor,
}: FooterProps) {
  const richTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })
  const creditTextStyle = useStyle({ textPreset: 'text-13' })

  return (
    <footer
      className={cx(className, css.Footer)}
      style={{ color, backgroundColor }}>
      <Separator fullViewportWidth className={css.separator} />
      <SplittedSection
        className={css.splittedSection}
        withVerticalSeparator
        startClassName={css.start}
        endClassName={css.end}
        start={
          <>
            {links?.length > 0 && (
              <ul>
                {links?.map((link, index) => (
                  <li key={`${link?.href}-${index}`}>
                    <InlineCta withCircle textPreset="cta-30" {...link} />
                  </li>
                ))}
              </ul>
            )}
            <Logotype className={css.logoType} />
          </>
        }
        end={
          <>
            {moreInfos?.length > 0 &&
              moreInfos?.map((info, index) => {
                return (
                  <RichText
                    className={cx(css.moreInfos, richTextStyle)}
                    key={index}
                    render={info}
                  />
                )
              })}
            {socialNetworks && (
              <RichText
                className={cx(css.socialNetworks, richTextStyle)}
                render={socialNetworks}
              />
            )}
            {credit && (
              <RichText
                className={cx(css.credit, creditTextStyle)}
                render={credit}
              />
            )}
          </>
        }
      />
    </footer>
  )
}

Footer.defaultProps = {}

export default Footer
