import classnames from 'classnames/bind'
import React from 'react'

import InlineCta from '~/components/InlineCta'
import Link from '~/components/Link'
import Logo from '~/components/Logo'

import { usePageSettings } from '~/providers/PageSettingsProvider'
import { usePageTransitionProvider } from '~/providers/PageTransitionProvider'
import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import AnimatedLogo from '../AnimatedLogo'
import { NavigationProps } from '../Navigation'
import Separator from '../Separator'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function Navigation({ className, contactUs, links, style }: NavigationProps) {
  const { backgroundColor, color } = usePageSettings()
  const { isTransitioning } = usePageTransitionProvider()
  const gridStyle = useStyle({ grid: 'base-grid' })

  const locale = useLocale()
  const logoHref = `/${locale}`

  const linkCircleTheme =
    backgroundColor === '#F06326' ? { circleTheme: 'white' } : {}

  return (
    <header
      className={cx(css.DesktopNavigation, className)}
      style={{ backgroundColor, color, ...style }}>
      <div className={gridStyle}>
        <div className={css.content}>
          <Separator className={css.separator} />
          <nav className={css.linksContainer}>
            <Link href={logoHref} className={css.logoContainer}>
              <AnimatedLogo className={css.animatedLogo} />
              <Logo className={css.logo} />
            </Link>
            <ul className={css.links}>
              {links?.map((link, index) => (
                <li className={css.link} key={index}>
                  <InlineCta
                    withCircle
                    textPreset="cta-13"
                    {...link}
                    {...linkCircleTheme}
                  />
                </li>
              ))}
            </ul>
          </nav>
          {contactUs && (
            <InlineCta
              className={css.contactUs}
              withArrow
              textPreset="cta-18"
              {...contactUs}
            />
          )}
        </div>
      </div>
    </header>
  )
}

Navigation.defaultProps = {}

export default Navigation
