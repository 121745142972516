import classnames from 'classnames/bind'
import React from 'react'

import { ReactComponent as LogoIcon } from './logo.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LogoProps {
  className?: string
}

function Logo({ className }: LogoProps) {
  return <LogoIcon className={cx(css.LogoIcon, className)} />
}

export default Logo
