import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useInterval } from 'react-use'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import PageIntro from '~/components/PageIntro'

import { Settings } from '~/data/serialize-settings'

const Context = createContext<PageTransitionStateProps>({})

export function usePageTransitionProvider() {
  return useContext(Context)
}

export interface PageTransitionProviderProps {
  children?: ReactNode | ReactNode[]
  settings?: Settings
}

export interface PageTransitionStateProps {
  isReady?: boolean
  isTransitioning?: boolean
  setTransitioning?: () => void
  unsetTransitioning?: () => void
  withoutIntro?: boolean
}
function PageTransitionProvider({
  children,
  settings,
}: PageTransitionProviderProps) {
  const [intro, setIntro] = useState(true)
  const [isReady, setIsReady] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)

  const setTransitioning = useCallback(() => {
    setIsTransitioning(true)
  }, [])

  const unsetTransitioning = useCallback(() => {
    setIsTransitioning(false)
  }, [])

  useIsomorphicLayoutEffect(() => {
    isTransitioning
      ? document?.documentElement?.classList?.add('is-animated')
      : document?.documentElement?.classList?.remove('is-animated')
  }, [isTransitioning])

  const providerValue: PageTransitionStateProps = useMemo(
    () => ({
      intro,
      isReady,
      isTransitioning,
      setTransitioning,
      unsetTransitioning,
    }),
    [isReady, intro, isTransitioning, setTransitioning, unsetTransitioning],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

PageTransitionProvider.defaultProps = {}

export default PageTransitionProvider
