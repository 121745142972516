import NextImage, {
  ImageLoaderProps,
  ImageProps as NextImageProps,
} from 'next/image'
import React, { forwardRef } from 'react'

import isDev from '~/utils/is-dev'

import { Size, useSizesFromBreakpoints } from './maths'

export type ImageProps = Omit<NextImageProps, 'src' | 'width' | 'height'> & {
  sizesFromBreakpoints?: Size[]
  width?: number
  height?: number
  src: string
}

export const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return src.indexOf('.svg') === -1
    ? src.replace(
        '//a.storyblok.com',
        `//img2.storyblok.com/${width}x0/filters:quality(${quality || 70})`,
      )
    : src
}

const Image = forwardRef<HTMLDivElement, ImageProps>(
  (
    {
      sizes,
      layout,
      sizesFromBreakpoints,
      width,
      height,
      className,
      ...props
    }: ImageProps,
    ref,
  ) => {
    const processedSizes = typeof useSizesFromBreakpoints(
      sizesFromBreakpoints,
      props.src,
    )

    if (!props.src)
      isDev &&
        console.warn(
          `[WARNING] Image-Component is use without SRC ${JSON.stringify(
            props,
          )}`,
        )

    return props.src ? (
      <div ref={ref} className={className}>
        {/* @ts-ignore */}
        <NextImage
          sizes={processedSizes ? processedSizes : sizes}
          layout={layout}
          {...(layout === 'fill' ? {} : { width, height })}
          loader={imageLoader}
          {...props}
        />
      </div>
    ) : null
  },
)

Image.defaultProps = {}

export default Image
