import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'

import InlineCta from '~/components/InlineCta'
import Link from '~/components/Link'
import Logo from '~/components/Logo'

import { usePageSettings } from '~/providers/PageSettingsProvider'
import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import { easeInOutQuint, easeOutQuint } from '~/utils/ease'

import { NavigationProps } from '../Navigation'
import Separator from '../Separator'
import css from './styles.module.scss'

const cx = classnames.bind(css)

/**
 * Navigation component
 * @param props
 * @returns Component
 */
const smallScreenPanelTransition = {
  type: 'ease',
  ease: easeOutQuint,
  duration: 0.8,
}

const smallScreenPanelVariants = {
  open: {
    y: '0%',
    transition: { ...smallScreenPanelTransition },
  },
  close: {
    y: '-100%',
    transition: {
      ...smallScreenPanelTransition,
      ease: easeInOutQuint,
      duration: 0.5,
    },
  },
}

function Navigation({ className, links, style }: NavigationProps) {
  const { backgroundColor } = usePageSettings()
  const gridStyle = useStyle({ grid: 'base-grid' })
  const [navIsOpen, setNavIsOpen] = useState(false)
  const t = useTranslate()

  const locale = useLocale()
  const logoHref = `/${locale}`

  return (
    <header
      className={cx(css.DeviceNavigation, className, gridStyle)}
      style={{ backgroundColor, ...style }}>
      <div className={css.content}>
        <m.ul
          initial="close"
          animate={navIsOpen ? 'open' : 'close'}
          variants={smallScreenPanelVariants}
          style={{ backgroundColor }}
          className={css.smallScreenPanel}>
          <div className={css.background} style={{ backgroundColor }} />
          {links?.map((link, index) => (
            <li className={css.link} key={index}>
              <InlineCta
                onClick={() => setNavIsOpen(false)}
                textPreset="cta-30"
                {...link}
              />
            </li>
          ))}
        </m.ul>
        <div className={css.background} style={{ backgroundColor }} />
        <nav className={css.linksContainer}>
          <Link href={logoHref} className={css.logoMask}>
            <Logo className={css.logo} />
          </Link>
        </nav>
        <InlineCta
          onClick={() => setNavIsOpen(!navIsOpen)}
          className={css.toggleMenuButton}
          textPreset="cta-13">
          {navIsOpen ? t('Close') : t('Menu')}
        </InlineCta>
      </div>
      <Separator className={css.separator} />
    </header>
  )
}

Navigation.defaultProps = {}

export default Navigation
