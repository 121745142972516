import { LazyMotion } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { ReactNode, useMemo } from 'react'
import { I18n } from 'react-polyglot'

import { LinkAlertProvider } from '~/components/LinkAlert'

import CssVariablesProvider from '~/providers/CssVariablesProvider'
import GlobalDataProvider from '~/providers/GlobalDataProvider'
import NavigationProvider from '~/providers/NavigationProvider'
import StyleProvider from '~/providers/StyleProvider'
import ThemeProvider from '~/providers/ThemeProvider'

import { DefaultPageData } from '~/data/page-data/serializer'

import gridPresets from '~/styles/grid-presets/styles.module.scss'
import ratioPresets from '~/styles/ratio-presets/styles.module.scss'
import textPresets from '~/styles/text-presets/styles.module.scss'
import backgroundColors from '~/styles/theme-classes/background-color.module.scss'
import colors from '~/styles/theme-classes/colors.module.scss'
import themePresets from '~/styles/theme-presets/styles.module.scss'

const motionAsyncFeatures = () =>
  import('~/utils/motion-features').then((res) => res.default)

export interface AppProviderProps {
  children: ReactNode | ReactNode[]
  pageProps: DefaultPageData
  // global: GlobalProps
}

const stylePresets = {
  textPreset: textPresets,
  color: colors,
  grid: gridPresets,
  ratio: ratioPresets,
  backgroundColor: backgroundColors,
}

const cssVariablesValue = {
  'scroll-bar': '0px',
  'vh-100': '100vh',
  'scroll-bar-grid': '0px',
}
const navigationProviderValue = { open: false }
function AppProvider({ children, pageProps }: AppProviderProps) {
  const { locale } = useRouter()

  const memoizedGlobalProviderData = useMemo(
    () => ({
      ...pageProps?.globalData,
      global: pageProps?.global,
      redirects: pageProps?.global?.redirects,
    }),
    [pageProps?.globalData, pageProps?.global?.redirects],
  )

  return (
    <LazyMotion strict features={motionAsyncFeatures}>
      <LinkAlertProvider>
        <I18n
          locale={locale}
          messages={pageProps?.global?.dictionary}
          allowMissing={true}>
          <GlobalDataProvider value={memoizedGlobalProviderData}>
            <StyleProvider value={stylePresets}>
              <ThemeProvider value={themePresets}>
                <CssVariablesProvider value={cssVariablesValue}>
                  <NavigationProvider value={navigationProviderValue}>
                    {children}
                  </NavigationProvider>
                </CssVariablesProvider>
              </ThemeProvider>
            </StyleProvider>
          </GlobalDataProvider>
        </I18n>
      </LinkAlertProvider>
    </LazyMotion>
  )
}

AppProvider.defaultProps = {}

export default AppProvider
