import classnames from 'classnames'
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React, { CSSProperties, DOMAttributes, ReactNode } from 'react'

export type LinkProps = NextLinkProps &
  DOMAttributes<any> & {
    className?: string
    activeClassName?: string
    children?: ReactNode | ReactNode[]
    isExternal?: boolean
    target?: string | null
    title?: string
    style?: CSSProperties
  }
/**
 * Next link overload component
 * @param props
 * @returns Component
 */
function Link({
  className,
  children,
  href = '',
  as,
  prefetch,
  scroll,
  shallow,
  passHref,
  replace,
  isExternal,
  activeClassName,
  onMouseEnter,
  onClick,
  ...rest
}: LinkProps) {
  const router = useRouter()
  const isActive = !isExternal && href === router?.asPath
  return isExternal ? (
    <a
      className={className}
      href={href as string}
      rel="noreferrer noopener"
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      {...rest}>
      {children}
    </a>
  ) : (
    <NextLink
      {...{
        href,
        as,
        replace,
        scroll,
        shallow,
        passHref,
        prefetch,
      }}>
      <a
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        className={classnames(className, {
          [activeClassName]: activeClassName && isActive,
        })}
        {...rest}>
        {children}
      </a>
    </NextLink>
  )
}

Link.defaultProps = {}

export { default as linkResolver } from '~/utils/link-resolver'

export default Link
