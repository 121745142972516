import linkResolver from '~/utils/link-resolver'

const defaultLink = { href: '/' }

// const processLink = (link) => (link?.startsWith('/') ? link : `/${link}`)

/**
 * Link serializer
 * @param link
 * @returns Serialized link
 */
export default function serializeLink(link) {
  if (link?.story?.content?.component) {
    return {
      href: linkResolver(link?.story) ?? null,
      rel: 'noopener',
    }
  } else if (link?.content?.component) {
    return {
      href: linkResolver(link) ?? null,
      rel: 'noopener',
    }
  } else if (link?.linktype === 'url') {
    return {
      href: link?.href ?? link?.url ?? null,
      isExternal: true,
      target: 'blank',
      rel: 'noopener nofollow',
    }
  } else {
    if (link?.href) {
      return {
        href: link?.href ?? link?.url ?? null,
        isExternal: true,
        target: 'blank',
        rel: 'noopener nofollow',
      }
    }
  }

  return defaultLink
}
