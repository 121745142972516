import { EasingFunction } from 'framer-motion'

export const easeInOutCubic: EasingFunction = (x) => {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
}
export const easeInQuart: EasingFunction = (x) => {
  return x * x * x * x * x
}
export const easeOutQuart: EasingFunction = (x) => {
  return 1 - Math.pow(1 - x, 4)
}
export const easeInOutQuart: EasingFunction = (x) => {
  return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2
}

export const easeInQuint: EasingFunction = (x) => {
  return x * x * x * x * x
}
export const easeOutQuint: EasingFunction = (x) => {
  return 1 - Math.pow(1 - x, 5)
}
export const easeInOutQuint: EasingFunction = (x) => {
  return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2
}

export const easeInExpo: EasingFunction = (x) => {
  return x === 0 ? 0 : Math.pow(2, 10 * x - 10)
}
export const easeOutExpo: EasingFunction = (x) => {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
}
export const easeInOutExpo: EasingFunction = (x) => {
  return x === 0
    ? 0
    : x === 1
    ? 1
    : x < 0.5
    ? Math.pow(2, 20 * x - 10) / 2
    : (2 - Math.pow(2, -20 * x + 10)) / 2
}
export const easeStep: EasingFunction = (x) => {
  return x < 1 ? 0 : 1
}
