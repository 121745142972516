import Head from 'next/head'
import React from 'react'

import { Language, PageMeta } from '~/data/page-data/meta'

export interface IntlMetaProps {
  meta: PageMeta
}

/**
 * Alternate langages
 * @param language
 * @returns Link alternate
 */
function generateAlternateMeta(language: Language) {
  return (
    <link
      rel="alternate"
      href={language?.url}
      hrefLang={language?.lang}
      key={`alternate-${language?.lang}`}
    />
  )
}
/**
 * Metadata component
 * @param props
 * @returns Component
 */
function IntlMeta({ meta }: IntlMetaProps) {
  const title = `Unlikely ${meta?.title ? `- ${meta?.title}` : ''}`
  const desc =
    meta?.description ??
    'Unlikely is a technology partner building bespoke online shopping and marketing experiences for modern brands.'
  const image = meta?.image ?? {
    src: 'https://unlikely.technology/unlikely_card.jpg',
  }
  const url = meta?.url ?? 'https://unlikely.technology/'

  return meta ? (
    <Head>
      <meta charSet="utf-8" />
      <title>{`${title}`}</title>
      {desc && <meta name="description" content={desc} />}
      <meta
        name="google-site-verification"
        content="XigAGkwf-8cfJfE-xT5NWbjmCHRvSXbq2Lbf1GKqtdM"
      />
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={`${title}`} key="title" />
      <meta property="og:url" content={url} />
      {desc && <meta property="og:description" content={desc} />}
      {image && <meta property="og:image" content={image?.src} />}

      {generateAlternateMeta(meta)}
      {meta?.alternateLanguages?.map((language) =>
        generateAlternateMeta(language),
      )}
      <meta property="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@unlikelytech" />
      <meta name="twitter:creator" content="@unlikelytech" />

      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={`${title}`} />
      {desc && <meta property="twitter:description" content={desc} />}
      {image && <meta property="twitter:image" content={image?.src} />}
      {image && <meta property="twitter:image:src" content={image?.src} />}
      {url && <link rel="canonical" href={url} key={`canonical-${url}`} />}
    </Head>
  ) : null
}

IntlMeta.defaultProps = {}

export default IntlMeta
