import classnames from 'classnames/bind'
import { m, useAnimation } from 'framer-motion'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { useMouseHovered } from 'react-use'

import { useIsHover, useIsTouchScreen } from '@unlikelystudio/react-hooks'

import { usePageSettings } from '~/providers/PageSettingsProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BigTitleProps {
  className?: string
  children?: ReactNode | ReactNode[]
}

interface AnimatedCursorProps {
  className?: string
  isInViewport: boolean
  isHover: boolean
  mainRef?: any
}

interface LetterProps {
  animate?: string
  letter: string
  custom: string
  shouldPlayAnimation: boolean
  isTouch: boolean
  index: number
}

const letterVariants = {
  initial: {
    rotate: 0,
    transformPerspective: '1000px',
    transition: {
      duration: 0,
    },
  },
}

function Letter({
  animate,
  custom,
  letter,
  shouldPlayAnimation,
  isTouch,
  index,
}: LetterProps) {
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false)

  const computedSizes = useMemo(
    () => ({
      rotationOffset: 360,
      rotationDirection: Math.floor(Math.random() * 200) % 2 === 0 ? -1 : 1,
    }),
    [],
  )

  const startAnimation = useCallback(
    (callback = null) => {
      if (!isAnimationPlaying && shouldPlayAnimation) {
        setIsAnimationPlaying(true)
        // @ts-ignore
        letterAnimationControls
          .start(letterAnimation.variants.isHover)
          .then(() => {
            stopAnimation()
            callback && callback()
            letterAnimationControls.start(letterAnimation.variants.initial)
          })
      }
    },
    [isAnimationPlaying, shouldPlayAnimation],
  )

  const stopAnimation = useCallback(() => {
    setIsAnimationPlaying(false)
  }, [])

  const letterAnimationControls = useAnimation()

  const letterAnimation = {
    initial: 'initial',
    animate: letterAnimationControls,
    variants: {
      initial: letterVariants.initial,
      isHover: {
        rotate: computedSizes.rotationDirection * computedSizes.rotationOffset,
        transition: {
          repeat: 0,
          type: 'spring',
          damping: 10,
          stiffness: 100,
          mass: 0.7,
        },
      },
    },
    onAnimationComplete: stopAnimation,
  }

  const [isHover, hoverEvents] = useIsHover({
    onMouseEnter: () => {
      startAnimation()
    },
  })

  // useEffect(() => {
  //   // This is a strange fuckery.
  //   let staggerTiming = 100 + (index + 1) * 50
  //   let intervalTiming = 6000
  //   let interval
  //   let timeout

  //   const staggerCallback = () => {
  //     interval = setInterval(() => {
  //       startAnimation()
  //     }, intervalTiming + staggerTiming)
  //   }

  //   if (shouldPlayAnimation) {
  //     timeout = setTimeout(() => {
  //       startAnimation(staggerCallback)
  //     }, staggerTiming)
  //   }

  //   return () => {
  //     stopAnimation()
  //     clearTimeout(timeout)
  //     clearInterval(interval)
  //   }
  // }, [shouldPlayAnimation])

  const { backgroundColor } = usePageSettings()

  return isTouch ? (
    <span>{letter}</span>
  ) : (
    <span
      className={css.hitbox}
      {...(!isAnimationPlaying && shouldPlayAnimation ? hoverEvents : {})}>
      {/* @ts-ignore */}
      <m.span
        custom={computedSizes.rotationOffset}
        className={cx(css.letter, { isAnimating: isAnimationPlaying })}
        transformTemplate={({ rotate }) => {
          return `rotate3d(1, 1, 0.5, ${rotate})`
        }}
        {...letterAnimation}>
        <>
          <m.span className={css.realLetter}>{letter}</m.span>
          <m.span
            style={{ color: backgroundColor }}
            className={css.shadowLetter}>
            {letter}
          </m.span>
          <m.span className={css.shadowLetter}>{letter}</m.span>
        </>
      </m.span>
    </span>
  )
}

function AnimatedCursor({
  className,
  isInViewport,
  mainRef,
  isHover,
}: AnimatedCursorProps) {
  const cursorAnimation = useAnimation()
  const { elX, elY } = mainRef
    ? useMouseHovered(mainRef, { whenHovered: false })
    : { elX: 0, elY: 0 }

  useEffect(() => {
    const position = {
      x: elX,
      y: elY,
    }

    cursorAnimation.start({
      ...(isHover ? position : {}),
      transition: { type: 'spring', damping: 50, stiffness: 600 },
    })
  }, [elX, elY, isHover])

  useEffect(() => {
    cursorAnimation.start({
      opacity: isHover ? 1 : 0,
      scale: isHover ? 1 : 0,
      transition: {
        type: 'spring',
        damping: 22,
        stiffness: 200,
        restDelta: 0.001,
      },
    })
  }, [isHover])

  return isInViewport ? (
    <m.div
      initial={{ opacity: 0 }}
      className={css.cursor}
      animate={cursorAnimation}>
      <div className={cx(css.cursorImage, { isHover })}></div>
    </m.div>
  ) : null
}

function BigTitle({ className, children }: BigTitleProps) {
  const [inViewRef, inView] = useInView()
  const [isHover, events] = useIsHover()
  const isTouchEvents = useIsTouchScreen()
  const bigTitleRef = useRef(null)
  let letterCount = 0

  return (
    <span ref={bigTitleRef} className={cx(css.BigTitle, className)} {...events}>
      {/* {!isTouchEvents && (
        <AnimatedCursor
          className={css.cursorHandler}
          isInViewport={inView}
          mainRef={bigTitleRef ? bigTitleRef : null}
          isHover={isHover}
        />
      )} */}
      <div ref={inViewRef} className={css.wordsWrapper}>
        {typeof children === 'string'
          ? children.split?.(' ')?.map((word, wordIndex) => {
              return (
                <span className={css.word} key={`${word}-${wordIndex}`}>
                  {word?.split('')?.map((letter, index) => {
                    return (
                      <Letter
                        index={letterCount++}
                        isTouch={isTouchEvents}
                        shouldPlayAnimation={inView}
                        letter={letter}
                        custom={`${word}-${wordIndex}-${letter}-${index}`}
                        key={`${letter}-${index}-${wordIndex}`}
                      />
                    )
                  })}
                  <span>&nbsp;</span>
                </span>
              )
            })
          : children}
      </div>
    </span>
  )
}

BigTitle.defaultProps = {}

export default BigTitle
