import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import React, {
  ReactNode,
  useState,
  useEffect,
  createContext,
  useContext,
  MouseEventHandler,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslate } from 'react-polyglot'
import { useDebouncedCallback } from 'use-debounce'

import Link, { LinkProps } from '~/components/Link'

import { useStyle } from '~/providers/StyleProvider'

import { easeOutExpo } from '~/utils/ease'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LinkAlertProps {
  className?: string
  link?: LinkProps
  children?: ReactNode | ReactNode[]
  color?: string
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
}

export interface LinkAlertProviderProps {
  children?: ReactNode | ReactNode[]
}

const LinkAlertContext = createContext([])
const SetLinkAlertContext = createContext<
  React.Dispatch<React.SetStateAction<any>>
>(() => {})

export function LinkAlertProvider({ children }) {
  const [alerts, setAlerts] = useState([])
  return (
    <LinkAlertContext.Provider value={alerts}>
      <SetLinkAlertContext.Provider value={setAlerts}>
        {children}
      </SetLinkAlertContext.Provider>
    </LinkAlertContext.Provider>
  )
}

const ease = {
  type: 'ease',
  ease: easeOutExpo,
  duration: 1.1,
}

const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 150,
  layoutY: {
    ...ease,
    duration: 0.8,
  },
}

const linkAlertVariant = {
  initialOpacity: { opacity: 0 },
  animateOpacity: {
    opacity: 1,
    // transition: { ...transition, duration: 0.2 },
  },
  initialX: { x: -250 },
  animateX: {
    x: 0,
    // transition: { ...ease },
  },
  exit: {
    opacity: 0,
    transition: {
      delay: 0,
      ease: 'easeIn',
      duration: 0.2,
    },
  },
}

const backgroundLinkAlertVariant = {
  initial: { x: -css.alertWidth },
  animate: {
    x: 0,
    transition: { ...ease, duration: ease.duration + 0.23, delay: 0.23 },
  },
}

const childrenLinkAlertVariant = {
  initial: { opacity: 0, x: -css.alertWidth },
  animate: {
    opacity: 1,
    x: 0,
    transition: { ...ease, duration: ease.duration + 0.3, delay: 0.3 },
  },
}

export function LinkAlert({
  color,
  className,
  children,
  onMouseEnter,
  onMouseLeave,
  link,
}: LinkAlertProps) {
  const t = useTranslate()
  const textStyle = useStyle({ textPreset: 'cta-18' })
  return (
    <m.li
      variants={linkAlertVariant}
      initial={['initialOpacity', 'initialX']}
      animate={['animateOpacity', 'animateX']}
      exit="exit"
      layout="position"
      transition={transition}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(css.LinkAlert, className, textStyle)}>
      <Link {...link} target="_blank" className={css.link}>
        <m.span
          variants={backgroundLinkAlertVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          style={{ backgroundColor: color }}
          className={css.background}
        />
        <m.span
          variants={childrenLinkAlertVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          className={css.children}>
          <span className={css.childrenContent}>{children}</span>
          <span className={css.cta}>{t('read', { _: 'Read' })}</span>
        </m.span>
      </Link>
    </m.li>
  )
}

export interface LinkAlertCenterProps {
  className?: string
}
export function LinkAlertCenter({ className }: LinkAlertCenterProps) {
  const alerts = useContext(LinkAlertContext)
  const setAlerts = useContext(SetLinkAlertContext)

  useEffect(() => {
    return () => {
      setAlerts([])
    }
  }, [])

  return (
    <ul className={cx(css.LinkAlertCenter, className)}>
      <AnimatePresence>
        {alerts?.map?.((alert: LinkAlertProps) => {
          return <LinkAlert key={`${alert?.link?.href}`} {...alert} />
        })}
      </AnimatePresence>
    </ul>
  )
}

export function RegisterLinkAlert({ link, children, color }: LinkAlertProps) {
  const [inViewRef, inView] = useInView({ triggerOnce: false })
  const setAlerts = useContext(SetLinkAlertContext)

  const debouncedCloseAlert = useDebouncedCallback(() => {
    setAlerts((alerts: LinkAlertProps[]) =>
      alerts?.filter((alert) => alert.link.href !== link.href),
    )
  }, 1200)

  useEffect(() => {
    if (!inView) {
      debouncedCloseAlert()
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      debouncedCloseAlert.cancel()
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      const props = {
        link,
        key: `${Math.random()}`,
        children,
        color,
      }
      setAlerts((alerts: LinkAlertProps[]) => {
        return alerts?.find((alert) => alert.link.href === link.href)
          ? alerts
          : [...(alerts ? alerts : []), props]
      })
      // debouncedCloseAlert()
    }
  }, [inView])

  return <span ref={inViewRef} className={cx(css.RegisterLinkAlert)} />
}
