import classnames from 'classnames/bind'
import React, { forwardRef, ReactNode } from 'react'

import { useStyle } from '~/providers/StyleProvider'

import Separator from '../Separator'
import css from './styles.module.scss'

const cx = classnames.bind(css)

type Children = ReactNode | ReactNode[]

export interface SplittedSectionProps {
  className?: string
  start?: Children
  startClassName?: string
  endClassName?: string
  end?: Children
  topChildren?: Children
  bottomChildren?: Children
  withVerticalSeparator?: boolean
  withTopSeparator?: boolean
  withBottomSeparator?: boolean
}

const SplittedSection = forwardRef<HTMLDivElement, SplittedSectionProps>(
  (
    {
      className,
      start,
      startClassName,
      end,
      endClassName,
      withTopSeparator,
      withBottomSeparator,
      withVerticalSeparator,
      topChildren,
      bottomChildren,
    }: SplittedSectionProps,
    ref,
  ) => {
    const gridStyle = useStyle({ grid: 'base-grid' })
    return (
      <div
        ref={ref}
        className={cx(className, css.SplittedSection, gridStyle, {
          withVerticalSeparator,
          withTopSeparator,
          withBottomSeparator,
        })}>
        {withTopSeparator && (
          <Separator className={cx(css.separator, css.topSeparator)} />
        )}
        {topChildren}
        {start && <div className={cx(css.start, startClassName)}>{start}</div>}
        {end && <div className={cx(css.end, endClassName)}>{end}</div>}
        {bottomChildren}
        {withBottomSeparator && (
          <Separator className={cx(css.separator, css.bottomSeparator)} />
        )}
      </div>
    )
  },
)

export default SplittedSection
