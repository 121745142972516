import classnames from 'classnames/bind'
import { Transition } from 'framer-motion'
import React, { createContext, ReactNode } from 'react'

import Footer from '~/components/Footer'
import IntlMeta from '~/components/IntlMeta'
import Navigation from '~/components/Navigation'

import { usePageSettings } from '~/providers/PageSettingsProvider'

import useStoryblok from '~/hooks/useStoryblok'

import { easeInOutExpo } from '~/utils/ease'

import { DefaultPageData } from '~/data/page-data/serializer'

import { LinkAlertCenter } from '../LinkAlert'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export const pageTransition: Transition = {
  type: 'tween',
  ease: easeInOutExpo,
  duration: 1.2,
}

/**
 * Context to transmit the current pageY to the useElevation hook
 */
export const PageYContext = createContext({ current: 0 })
export interface PageProps extends DefaultPageData {
  children?: ReactNode | ReactNode[]
}
/**
 * Page abstraction layout
 * @param props
 * @returns Component
 */
function Page({ children, meta, global, preview }: PageProps) {
  useStoryblok(preview)

  const { color, backgroundColor } = usePageSettings()

  return (
    <>
      <main
        style={{
          color: color,
          backgroundColor: backgroundColor,
        }}
        className={cx(css.Page, color)}>
        <div className={css.mask}>
          <IntlMeta meta={meta} />
          <div className={css.content}>{children}</div>
          <Footer {...global?.footer} />
          <div>
            <Navigation {...global?.navigation} />
          </div>
          <LinkAlertCenter />
        </div>
      </main>
    </>
  )
}

Page.defaultProps = {}

export default Page
