import { imageLoader } from '~/components/Image'

export interface Image {
  src: string
  width: number
  height: number
  alt?: string
}

export interface ImageOptions {
  filename?: string
  src?: string
  alt?: string
  name?: string
}

export interface LoaderParams {
  width: number
  quality: number
}

/**
 * Image serializer
 * @param image
 * @param loaderParams
 * @returns Serialized image
 */
export default function serializeImage(
  image: ImageOptions,
  loaderParams?: LoaderParams,
): Image {
  const src = image?.filename ?? image?.src
  const alt = image?.alt ?? null
  let newImageSrc: string

  const [width, height] = src ? src.split('/')[5].split('x') : []

  let dimensions = {
    width: width ? parseInt(width) : null,
    height: height ? parseInt(height) : null,
  }

  if (src) {
    let url = new URL(src)
    newImageSrc = `${url?.origin}${url?.pathname}`

    newImageSrc =
      loaderParams && src.indexOf('.svg') === -1
        ? imageLoader({
            src: newImageSrc,
            ...loaderParams,
          })
        : newImageSrc
  }

  return src
    ? {
        src: newImageSrc ?? src,
        width: dimensions?.width,
        height: dimensions?.height,
        alt,
      }
    : null
}
