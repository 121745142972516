import classnames from 'classnames/bind'
import { LinkProps } from 'next/link'
import React, { CSSProperties } from 'react'

import { InlineCtaProps } from '~/components/InlineCta'

import DesktopNavigation from '../DesktopNavigation'
import DeviceNavigation from '../DeviceNavigation'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NavigationProps {
  className?: string
  links?: InlineCtaProps[]
  contactUs: LinkProps
  style?: CSSProperties
}

function Navigation(navigationProps: NavigationProps) {
  return (
    <>
      <DesktopNavigation
        className={cx(css.Navigation, css.desktop)}
        {...navigationProps}
      />
      <DeviceNavigation
        className={cx(css.Navigation, css.device)}
        {...navigationProps}
      />
    </>
  )
}

export default Navigation
