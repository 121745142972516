import React, { useContext, createContext, useState, ReactNode } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

export const CssVariablesContext = createContext<ValueType>({})

interface CssVariablesProviderProps {
  value: object
  children: ReactNode | ReactNode[]
}

export type VariablesType = Record<string, any>

export interface ValueType {
  variables?: VariablesType
  update?: (id: string, value: any) => void
}

export default function CssVariablesProvider({
  value,
  children,
}: CssVariablesProviderProps) {
  const [variables, setVariables] = useState({})

  const update = (id, val) => {
    setVariables((variables) => ({ ...variables, [id]: val }))
  }

  const processedValue: ValueType = {
    variables: { ...value, ...variables },
    update,
  }

  useIsomorphicLayoutEffect(() => {
    const body = document.querySelector('body')
    Object.keys(variables).forEach((key) => {
      body.style.setProperty(`--${key}`, variables[key])
    })
  }, [variables])

  return (
    <CssVariablesContext.Provider value={processedValue}>
      {children}
    </CssVariablesContext.Provider>
  )
}

export function useCssVariables() {
  return useContext(CssVariablesContext)
}
