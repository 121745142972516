import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import React, { DOMAttributes, ReactNode } from 'react'
import type { UrlObject } from 'url'

import { useIsHover } from '@unlikelystudio/react-hooks'

import Link, { LinkProps } from '~/components/Link'

import { useStyle } from '~/providers/StyleProvider'

import { easeInExpo, easeOutExpo, easeStep } from '~/utils/ease'

import { ReactComponent as ArrowIcon } from './arrow.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

interface BaseProps extends DOMAttributes<any> {
  className?: string
  lineClassName?: string
  href?: string | UrlObject
  children?: ReactNode
  linkType?: 'Web' | 'Document' | 'Media'
  textPreset?: string
  withArrow?: boolean
  withCircle?: boolean
  withLine?: boolean
  disabled?: boolean
  icon?: boolean
  circleTheme?: string
  ctaType?: 'inline'
}

const transition = {
  type: 'tween',
  ease: [easeInExpo, easeStep, easeOutExpo],
  duration: 1,
  times: [0, 0.4, 0.4, 1],
}

const arrowVariants = {
  isHover: {
    x: ['0%', '100%', '-100%', '0%'],
    transition,
  },
}

const childrenVariants = {
  isHover: {
    x: ['0%', '100%', '-100%', '0%'],
    transition,
  },
}
const circleVariants = {
  initial: {
    opacity: 0,
    rotate: 0,
    scale: 0.6,
  },
  isHover: {
    rotate: [0, 360],
    opacity: 1,
    scale: 1,
    transition: {
      times: [0, 0.5, 1],
    },
  },
}

export type InlineCtaProps = BaseProps | (LinkProps & BaseProps)
/**
 * Inline cta component
 * @param props
 * @returns Component
 */
function InlineCta({
  className,
  children,
  href,
  onMouseEnter,
  onMouseLeave,
  textPreset,
  linkType,
  ctaType,
  icon,
  circleTheme,
  withArrow,
  withLine,
  withCircle,
  ...rest
}: InlineCtaProps) {
  const ctaTextStyle = useStyle({
    textPreset,
  })

  const [isHover, events] = useIsHover()

  const withArrowAnimation = {
    initial: 'initial',
    transition: {
      type: 'spring',
      damping: 50,
      stiffness: 500,
    },
    animate: isHover && withArrow ? 'isHover' : 'initial',
  }

  const withCircleAnimation = {
    initial: 'initial',

    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 500,
    },
    animate: isHover && withCircle ? 'isHover' : 'initial',
  }

  const isExternal = linkType !== 'Document'
  const classNames = cx(
    className,
    css.InlineCta,
    ctaTextStyle,
    circleTheme ? css?.[`circleTheme-${circleTheme}`] : null,
    { withLine },
  )

  const innerContent = (
    <span {...events} className={css.children}>
      {withArrow && (
        <span className={css.animatedArrowMask}>
          <m.span
            {...withArrowAnimation}
            variants={arrowVariants}
            className={css.animatedArrow}>
            <ArrowIcon className={css.arrow} />
          </m.span>
        </span>
      )}
      <span className={cx(css.childrenMask, { withNoOverflow: withArrow })}>
        <m.span
          {...withArrowAnimation}
          variants={childrenVariants}
          className={css.children}>
          {children}
        </m.span>
      </span>
      {withCircle && (
        <m.span
          transformTemplate={({ rotate, scale }) =>
            `rotate3d(0.5, 1, 0.5, ${rotate}) scale(${
              typeof scale === 'number' ? scale : parseInt(scale)
            })`
          }
          className={css.circle}
          variants={circleVariants}
          {...withCircleAnimation}>
          <span className={css.innerCircle} />
          <span className={css.innerCircle} />
          <span className={css.innerCircle} />
        </m.span>
      )}
    </span>
  )

  return href ? (
    <Link href={href} className={classNames} isExternal={isExternal} {...rest}>
      {innerContent}
    </Link>
  ) : (
    <button className={classNames} {...rest}>
      {innerContent}
    </button>
  )
}

InlineCta.defaultProps = {
  theme: 'black',
  color: 'black',
  linkType: 'Document',
}

export default InlineCta
