import classnames from 'classnames/bind'
import React from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SeparatorProps {
  className?: string
  fullViewportWidth?: boolean
}

function Separator({ className, fullViewportWidth }: SeparatorProps) {
  return (
    <div className={cx(css.Separator, className, { fullViewportWidth })}>
      <div className={css.line}>
        <div className={css.lineContent} />
      </div>
    </div>
  )
}

export default Separator
