import { AnimatePresence } from 'framer-motion'
import { AppProps } from 'next/app'
import React from 'react'

import { useTriggerResize } from '@unlikelystudio/react-hooks'

import FixCss from '~/components/FixCss'
import Page from '~/components/Page'

import AppProviders from '~/providers/AppProviders'
import PageSettingsProvider from '~/providers/PageSettingsProvider'
import PageTransitionProvider from '~/providers/PageTransitionProvider'

import useCursorLoading from '~/hooks/useCursorLoading'
import useScrollRestoration from '~/hooks/useScrollRestoration'

import '../styles/globals.scss'

function MyApp({ Component, pageProps, router }: AppProps) {
  const key = router?.asPath?.split('?')?.[0]
  useCursorLoading()
  useScrollRestoration(router)

  const triggerResize = useTriggerResize()

  return (
    <AppProviders pageProps={pageProps}>
      {/* @ts-ignore */}
      <PageSettingsProvider key={key} value={pageProps.pageSettings}>
        <Page {...pageProps}>
          <Component {...pageProps} />
        </Page>
      </PageSettingsProvider>
    </AppProviders>
  )
}

export default MyApp
