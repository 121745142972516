import classnames from 'classnames/bind'
import React, {
  forwardRef,
  SourceHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
  VideoHTMLAttributes,
} from 'react'
import { useInView } from 'react-intersection-observer'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface VideoProps extends VideoHTMLAttributes<any> {
  className?: string
  loading?: string
  rootMargin?: string
  objectFit?: 'cover' | 'contain'
  src?: string
  poster?: string
  autoPlay?: boolean
  sources?: SourceHTMLAttributes<any>[]
}

/**
 * Video overload component
 * @param props
 * @returns Component
 */
const VideoComponent = forwardRef(
  (
    {
      className,
      loading,
      src,
      rootMargin,
      style,
      sources,
      poster,
      preload = 'none',
      autoPlay,
      ...rest
    }: VideoProps,
    ref: any,
  ) => {
    const shouldLazyLoad = loading === 'lazy'
    const wasInView = useRef(false)
    const [isPlaying, setIsPlaying] = useState(false)

    const [inViewRef, inView] = useInView({
      rootMargin,
    })

    const setRef = useCallback(
      (node) => {
        ref && (ref.current = node)
        inViewRef(node)

        if (autoPlay && node && !isPlaying) {
          node.play()
        }
      },
      [ref, inViewRef, autoPlay, isPlaying],
    )

    const onPlayCallback = useCallback(() => {
      if (!isPlaying) {
        setIsPlaying(true)
      }
    }, [])

    const onPauseCallback = useCallback(() => {
      if (isPlaying) {
        setIsPlaying(false)
      }
    }, [])

    const shouldHaveSrc = !shouldLazyLoad || (shouldLazyLoad && inView)
    return (
      <video
        preload={preload}
        // poster={poster}
        className={cx(className, css.Video)}
        onPlay={onPlayCallback}
        onPause={onPauseCallback}
        ref={setRef}
        src={shouldHaveSrc ? src : ''}
        autoPlay={autoPlay}
        style={{
          ...style,
          background: isPlaying
            ? 'unset'
            : `transparent url(${poster}) 50% 50% / cover`,
          visibility: shouldHaveSrc ? 'visible' : 'hidden',
        }}
        {...rest}>
        {shouldHaveSrc &&
          sources &&
          sources.length > 0 &&
          sources.map((source) => <source {...source} />)}
      </video>
    )
  },
)

VideoComponent.defaultProps = {
  loading: 'eager',
  rootMargin: '200px 0px',
}

export default VideoComponent
