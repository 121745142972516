export default function linkResolver(doc: any): string {
  switch (doc?.content?.component) {
    case 'project_page':
      return `/projects/${doc.slug}`
    case 'news_page':
      return `/news/${doc.slug}`
    case 'article_page':
      return `/blog/${doc.slug}`
    case 'home_page':
      return `/`
    default:
      return `/${doc?.slug}`
  }
}
