import React, { useMemo, createContext, useContext, ReactNode } from 'react'

const Context = createContext({})

function processCss(theme: object) {
  let t = {}
  for (const [key, value] of Object.entries(theme)) {
    const [map, mapKey] = key.split('___')
    if (!t[map]) t[map] = {}
    t[map][mapKey] = value
  }
  return t
}

/**
 * Export every map list declared into theme-presets to use as JS Variables like

 * @example
 * ```ts
 * const { screens, grid } = useTheme()
 * ```
 * @example
 * ```scss
 * theme-presets.module.scss
 * :export {
 *   @include mapToProps($colors, 'colors');
 *   @include mapToProps($grid, 'grid');
 *   @include mapToProps($screens, 'screens');
 * }
 * ```
 */
export function useTheme(): any {
  const ctx = useContext(Context)

  return useMemo(() => processCss(ctx), [])
}

export interface ThemeProviderProps {
  children: ReactNode | ReactNode[]
  value: object
}

export default function ThemeProvider({ value, children }: ThemeProviderProps) {
  return <Context.Provider value={value}>{children}</Context.Provider>
}
