import classnames from 'classnames/bind'
import React, { forwardRef } from 'react'
import {
  render,
  NODE_IMAGE,
  MARK_LINK,
  MARK_STYLED,
} from 'storyblok-rich-text-react-renderer'

import serializeCta from '~/data/serialize-cta'
import serializeImage from '~/data/serialize-image'
import serializeLink from '~/data/serialize-link'

import BigTitle from '../BigTitle'
import InlineCta from '../InlineCta'
import { RegisterLinkAlert } from '../LinkAlert'
import MediaWithPlaceholder from '../MediaWithPlaceholder'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export function isRTFilled(richText) {
  if (!richText) return false

  //@ts-ignore
  return richText?.content?.filter((item) => item.content)?.length > 0
}

export interface RichTextProps {
  className?: string
  children?: undefined
  innerRef?: any
  style?: object
  render?: any
}
/**
 * Prismic richtext component
 * @param props
 * @returns Component
 */
function RichText({
  className,
  innerRef,
  style,
  render: renderText,
}: RichTextProps) {
  return (
    <div ref={innerRef} className={cx(className, css.RichText)} style={style}>
      {isRTFilled(renderText)
        ? render(renderText, {
            markResolvers: {
              [MARK_STYLED]: (children, { class: className }) => {
                return className === 'big-title' ? (
                  <BigTitle className={className} children={children} />
                ) : (
                  <span className={className}>{children}</span>
                )
              },
              [MARK_LINK]: (children, props) => {
                const linkProps = serializeLink(props)
                return (
                  <InlineCta withLine {...linkProps}>
                    {children}
                  </InlineCta>
                )
              },
            },
            nodeResolvers: {
              [NODE_IMAGE]: (_children, props) => {
                return (
                  <MediaWithPlaceholder
                    className="rich-text-image"
                    sizesFromBreakpoints={[
                      {
                        breakpoint: 'md',
                        ratio: 0.1,
                      },
                      {
                        ratio: 1,
                      },
                    ]}
                    withRadius
                    imageProps={serializeImage(props)}
                  />
                )
              },
            },
            blokResolvers: {
              ['cta']: (props) => (
                <InlineCta className="cta" withArrow {...serializeCta(props)} />
              ),
              ['link_alert']: (props) => {
                return (
                  <RegisterLinkAlert
                    color={props?.color?.color}
                    children={props?.link?.[0]?.label}
                    link={serializeLink(props?.link?.[0]?.url)}
                  />
                )
              },
            },
          })
        : null}
    </div>
  )
}

export default forwardRef<HTMLDivElement, RichTextProps>((props, ref) => (
  <RichText innerRef={ref} {...props} />
))
