import classnames from 'classnames/bind'
import { m, MotionProps } from 'framer-motion'
import React, { forwardRef, Ref, useState } from 'react'

import Image, { ImageProps } from '~/components/Image'
import Video, { VideoProps } from '~/components/Video'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type MediaWithPlaceholderProps = {
  id?: string
  className?: string
  withRadius?: boolean
  shouldPlayVideo?: boolean
  motionStyle?: MotionProps['style']
  videoProps?: VideoProps
  imageProps?: ImageProps
  layout?: ImageProps['layout']
  objectFit?: ImageProps['objectFit']
  sizesFromBreakpoints?: ImageProps['sizesFromBreakpoints']
}

const MediaWithPlaceholder = forwardRef(
  (
    {
      className,
      withRadius = true,
      motionStyle,
      videoProps,
      imageProps,
      layout,
      objectFit,
      sizesFromBreakpoints,
      shouldPlayVideo,
      ...rest
    }: MediaWithPlaceholderProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [isLoaded, setIsLoaded] = useState(false)
    return (
      <div
        ref={ref}
        className={cx(className, css.MediaWithPlaceholder, {
          withRadius,
        })}>
        <m.div className={css.image} style={motionStyle}>
          {videoProps ? (
            <Video
              className={cx(css.image, css.video)}
              playsInline
              loop
              autoPlay={shouldPlayVideo}
              muted
              style={{ objectFit }}
              {...videoProps}
            />
          ) : (
            <>
              {imageProps?.src && (
                <Image
                  className={cx(css.image, css.imageContainer, { isLoaded })}
                  onLoadingComplete={() => setIsLoaded(true)}
                  layout={layout}
                  objectFit={objectFit}
                  sizesFromBreakpoints={sizesFromBreakpoints}
                  {...imageProps}
                  {...rest}
                />
              )}
            </>
          )}
        </m.div>
      </div>
    )
  },
)

MediaWithPlaceholder.defaultProps = {
  shouldPlayVideo: true,
}
export type { ImageProps } from '~/components/Image'
export type { VideoProps } from '~/components/Video'
export default MediaWithPlaceholder
