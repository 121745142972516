import { useRouter } from 'next/router'

/**
 * Get next locale from router
 * @returns locale
 */
function useLocale(): string {
  const router = useRouter()
  return router?.locale as string
}

export default useLocale
