import { useIsPresent } from 'framer-motion'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

import { Settings } from '~/data/serialize-settings'

export interface PageSettings extends Settings {
  forceColor: string
  forceBackgroundColor: string
}

const defaultPageSettings = {
  color: 'white',
  backgroundColor: 'black',
  forceColor: null,
  forceBackgroundColor: null,
}

const Context = createContext<PageSettings>(defaultPageSettings)
const SetterContext = createContext<
  React.Dispatch<React.SetStateAction<PageSettings>>
>(() => {})

export function usePageSettings(): any {
  const ctx = useContext(Context)
  return {
    color: ctx?.forceColor ?? ctx?.color,
    backgroundColor: ctx?.forceBackgroundColor ?? ctx?.backgroundColor,
  }
}
export function useSetPageSettings() {
  return useContext(SetterContext)
}

export interface PageSettingsProviderProps {
  value: Settings
  children: ReactNode | ReactNode[]
}

export default function PageSettingsProvider({
  value,
  children,
}: PageSettingsProviderProps) {
  const [pageSettings, setPageSettings] = useState<PageSettings>(
    { ...defaultPageSettings, ...value } ?? defaultPageSettings,
  )

  const isPresent = useIsPresent()
  const modifiedSetPageSettings = useCallback(
    (params) => {
      isPresent ? setPageSettings(params) : () => {}
    },
    [setPageSettings, isPresent],
  )

  return (
    <SetterContext.Provider value={modifiedSetPageSettings}>
      <Context.Provider value={pageSettings}>{children}</Context.Provider>
    </SetterContext.Provider>
  )
}
