import classnames from 'classnames/bind'
import { m, useIsPresent } from 'framer-motion'
import React, { useState } from 'react'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { usePageSettings } from '~/providers/PageSettingsProvider'
import { usePageTransitionProvider } from '~/providers/PageTransitionProvider'

import { easeInOutExpo, easeOutExpo } from '~/utils/ease'

import { ReactComponent as StripedCircle } from './striped-circle.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AnimatedLogoProps {
  className?: string
  withoutAnimation?: boolean
  customBackgroundColor?: string
}

const transitionIn = {
  duration: 1,
  type: 'tween',
  ease: easeInOutExpo,
}

const transitionOut = {
  duration: 1,
  type: 'tween',
  ease: easeOutExpo,
}

const plainCircleVariants = {
  initial: {
    z: 350,
  },
  animated: {
    z: 0,
  },
}

const strippedCircleVariants = {
  initial: {
    rotate: 30,
    clipPath: 'circle(0%)',
  },
  animated: {
    clipPath: 'circle(50%)',
    rotate: 0,
  },
}

enum AnimatedLogoState {
  INITIAL = 'initial',
  ANIMATED = 'animated',
}

function AnimatedLogo({
  className,
  withoutAnimation,
  customBackgroundColor,
}: AnimatedLogoProps) {
  const { backgroundColor, color } = usePageSettings()
  const { isReady, isTransitioning } = usePageTransitionProvider()

  const isPresent = withoutAnimation ? true : useIsPresent()

  const logoStatus = isPresent
    ? AnimatedLogoState.INITIAL
    : AnimatedLogoState.ANIMATED

  const animateLogoStatus = !isPresent
    ? AnimatedLogoState.INITIAL
    : AnimatedLogoState.ANIMATED
  const [status, setStatus] = useState<AnimatedLogoState | {}>({})

  useIsomorphicLayoutEffect(() => {
    if (isPresent && !isTransitioning) {
      setStatus(animateLogoStatus)
    } else {
      setStatus({})
    }
  }, [isTransitioning, isReady])

  const transition = isPresent ? transitionOut : transitionIn
  return (
    <div className={cx(className, css.AnimatedLogo)} style={{ color }}>
      <div className={css.square}>
        <div
          // initial={logoStatus}
          // animate={isPresent ? status : animateLogoStatus}
          // transition={transition}
          // variants={plainCircleVariants}
          className={css.plainCircle}
          style={{ backgroundColor: customBackgroundColor ?? backgroundColor }}
        />
      </div>
      <div className={css.square}>
        <div
        // initial={logoStatus}
        // animate={isPresent ? status : animateLogoStatus}
        // transition={transition}
        // variants={strippedCircleVariants}
        >
          <StripedCircle className={css.stripedCircle} />
        </div>
      </div>
    </div>
  )
}

export default AnimatedLogo
